import { i18n } from 'vue-lang-router';

export const formValidations = (input, type = null) => {
  let lang = i18n.locale;
  let errors = {};
  let regEmail =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,24}))$/;
  let regPhone =
    /^(4|)?(07[0-8]{1}[0-9]{1}|02[0-9]{2}|03[0-9]{2}){1}?(\s|\.|-)?([0-9]{3}(\s|\.|-|)){2}$/;

  let trimCheckItems = [
    'subject',
    'email',
    'password',
    'name',
    'first_name',
    'last_name',
    'alias',
    'county',
    'city',
    'address',
    'iban',
    'billing_label',
    'billing_name',
    'billing_county',
    'billing_city',
    'billing_address',
    'billing_postcode',
    'contact_label',
    'contact_name',
    'contact_county',
    'contact_city',
    'contact_address',
    'contact_postcode',
    'company_name',
    'company_label',
    'company_cif',
    'company_regnr',
    'company_county',
    'company_city',
    'company_address',
    'company_bank',
    'company_account',
  ];

  let phoneCheckItems = ['phone', 'billing_phone', 'contact_phone'];

  if (type == 'rating') {
    if (
      Object.prototype.hasOwnProperty.call(input, 'message') &&
      input.message.length > 1000
    ) {
      errors.message = i18n.messages[lang].form.validation.maxChars.replace(
        '{max}',
        1000
      );
    }
  } else {
    if (
      Object.prototype.hasOwnProperty.call(input, 'message') &&
      !input.message.trim()
    ) {
      errors.message = i18n.messages[lang].form.validation.message;
    } else if (
      Object.prototype.hasOwnProperty.call(input, 'message') &&
      input.message.length < 25 &&
      input.message.length <= 1000
    ) {
      let minError = i18n.messages[lang].form.validation.minMaxChars.replace(
        '{min}',
        25
      );
      errors.message = minError.replace('{max}', 1000);
    }
  }

  if (
    Object.prototype.hasOwnProperty.call(input, 'email') &&
    !input.email.trim()
  ) {
    errors.email = i18n.messages[lang].form.validation.email;
  } else if (
    Object.prototype.hasOwnProperty.call(input, 'email') &&
    !regEmail.test(input.email)
  ) {
    errors.email = i18n.messages[lang].form.validation.incorrectFormat;
  }

  if (
    Object.prototype.hasOwnProperty.call(input, 'password') &&
    Object.prototype.hasOwnProperty.call(input, 'passwordConfirmation') &&
    input.password.trim() != input.passwordConfirmation.trim()
  ) {
    errors.passwordConfirmation =
      i18n.messages[lang].form.validation.passwordRepeat;
  }

  if (
    Object.prototype.hasOwnProperty.call(input, 'products') &&
    !input.products
  ) {
    errors.products = i18n.messages[lang].form.validation.noSelectedProduct;
  }

  if (
    Object.prototype.hasOwnProperty.call(input, 'iban') &&
    !input.iban.trim()
  ) {
    errors.iban = i18n.messages[lang].form.validation.iban;
  } else if (
    Object.prototype.hasOwnProperty.call(input, 'iban') &&
    input.iban.length !== 24
  ) {
    errors.iban = i18n.messages[lang].form.validation.fieldSize.replace(
      '{size}',
      24
    );
  }

  if (
    Object.prototype.hasOwnProperty.call(input, 'type') &&
    Object.prototype.hasOwnProperty.call(input, 'description') &&
    input.type === 'warranty' &&
    !input.description.trim()
  ) {
    errors.description = i18n.messages[lang].form.validation.defective;
  }

  if (
    Object.prototype.hasOwnProperty.call(input, 'description') &&
    input.description &&
    input.description.length > 1000
  ) {
    errors.description = i18n.messages[lang].form.validation.maxChars.replace(
      '{max}',
      1000
    );
  }

  if (
    Object.prototype.hasOwnProperty.call(input, 'question') &&
    (input.question.trim().length < 20 || input.question.trim().length > 1000)
  ) {
    let minError = i18n.messages[lang].form.validation.minMaxChars.replace(
      '{min}',
      25
    );
    errors.question = minError.replace('{max}', 1000);
  }

  if (
    Object.prototype.hasOwnProperty.call(input, 'rating') &&
    (!input.rating || input.rating == 0)
  ) {
    errors.rating = i18n.messages[lang].form.validation.noRating;
  }

  if (
    Object.prototype.hasOwnProperty.call(input, 'productId') &&
    !input.productId
  ) {
    errors.productId = i18n.messages[lang].form.validation.noSelectedProduct;
  }

  for (const key of Object.keys(input)) {
    if (trimCheckItems.includes(key)) {
      checkAndTrim(input, key, key, i18n.messages[lang].form.validation[key]);
    }
    if (phoneCheckItems.includes(key)) {
      validatePhone(input, key, lang, errors);
    }
  }

  function checkAndTrim(input, property, errorKey, validationMessage) {
    if (
      Object.prototype.hasOwnProperty.call(input, property) &&
      (!input[property] || !input[property].trim())
    ) {
      errors[errorKey] = validationMessage;
    }
  }

  function validatePhone(input, property, lang, errors) {
    if (Object.prototype.hasOwnProperty.call(input, property)) {
      if (!input[property] || !input[property].trim()) {
        errors[property] = i18n.messages[lang].form.validation.phone;
      } else if (!regPhone.test(input[property])) {
        errors[property] = i18n.messages[lang].form.validation.incorrectFormat;
      }
    }
  }

  return errors;
};
