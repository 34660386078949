<template>
    <modal
        id="login"
        :title="$t('modal.login.heading')"
        type="general"
    >
        <form
            id="login"
            @submit.prevent="submitLogin"
        >
            <div
                class="form-group w100"
                :class="{ error: errors.email }"
            >
                <label for="email">{{ $t('form.fields.label.email_address') }}</label>
                <input
                    id="email"
                    v-model="input.email"
                    type="text"
                    class="form-control"
                    name="email"
                    autocomplete="on"
                >
                <span
                    v-if="errors.email"
                    class="error-message"
                >{{
                    errors.email
                }}</span>
            </div>
            <div
                class="form-group w100 has-icon"
                :class="{ error: errors.password }"
            >
                <label for="password">{{ $t('form.fields.label.password') }}</label>
                <input
                    v-if="!showPassword"
                    id="password"
                    v-model="input.password"
                    type="password"
                    class="form-control"
                    name="password"
                    autocomplete="on"
                >
                <input
                    v-if="showPassword"
                    v-model="input.password"
                    type="text"
                    class="form-control"
                    name="password"
                    autocomplete="on"
                >
                <span
                    v-if="errors.password"
                    class="error-message"
                >{{
                    errors.password
                }}</span>
                <img
                    v-if="!showPassword"
                    src="/images/fe_eye.svg"
                    alt="show password icon"
                    @click="showPassword = !showPassword"
                >
                <img
                    v-if="showPassword"
                    alt="hide password icon"
                    src="/images/fe_eye-off.svg"
                    @click="showPassword = !showPassword"
                >
            </div>
            <div
                class="form-group w100 general"
                :class="{ error: errors.general }"
            >
                <span
                    v-if="errors.general"
                    class="error-message"
                >{{
                    errors.general
                }}</span>
                <p
                    class="sm"
                    @click="openResetPassword()"
                >
                    {{ $t('modal.login.forgotPassword') }}
                </p>
            </div>
            <b-button
                v-if="possibilityToReSend"
                variant="primary"
                class="stroke lg login"
                :disabled="sendingResent"
                @click="reSend()"
            >
                {{ $t('modal.login.reSend') }}
            </b-button>
            <b-button
                :disabled="sending"
                variant="primary"
                class="fill lg login"
                type="submit"
            >
                {{ $t('modal.login.submit') }}
            </b-button>
        </form>
        <div class="separator d-flex">
            <hr>
            <p class="sm">
                {{ $t('modal.login.orText') }}
            </p>
            <hr>
        </div>
        <social-login />
        <div class="member d-flex">
            <p class="sm">
                {{ $t('modal.login.noAccountText') }}
            </p>
            <p
                class="login sm-sbold"
                @click="showRegisterPopup()"
            >
                {{ $t('modal.login.registerText') }}
            </p>
        </div>
    </modal>
</template>

<script>
    import { mapActions, mapGetters, mapMutations, mapState } from 'vuex';

    import { formValidations } from '@/services/formValidations';
    import { inputErrors } from '@/services/inputErrors';

    import Modal from './Modal.vue';
    import SocialLogin from './SocialLogin';
    export default {
        name: 'LoginModal',
        components: {
            Modal,
            SocialLogin,
        },
        props: {
            toast: {
                type: Boolean,
                default: false,
            },
        },
        data() {
            return {
                showModal: false,
                sending: false,
                sendingResent: false,
                showPassword: '',
                input: {
                    email: '',
                    password: '',
                },
                errors: {},
            };
        },
        computed: {
            ...mapState('auth', [
                'inputErrors',
                'confirmed',
                'possibilityToReSend',
                'redirectPath',
            ]),
            ...mapGetters('auth', ['isLogged']),
        },
        methods: {
            ...mapActions('auth', ['login', 'reSendVerification']),
            ...mapMutations('general', ['setModal']),
            ...mapMutations('user', ['setGeneralData']),
            ...mapMutations('auth', ['resetRedirectPath']),
            async submitLogin() {
                this.errors = formValidations(this.input);

                if (Object.keys(this.errors).length !== 0) {
                    return;
                }
                this.sending = true;
                await this.login({
                    email: this.input.email,
                    password: this.input.password,
                });
                this.sending = false;
                if (Object.keys(this.inputErrors).length > 0) {
                    this.errors = Object.assign({}, inputErrors(this.inputErrors));
                    return;
                }

                this.setGeneralData();
                // redirect after login it the request comes from another route than actual then reset it
                if (this.redirectPath) {
                    this.$router.push(this.redirectPath);
                }
                this.$toast.open({
                    message: this.$t('notification.loggedIn'),
                    duration: 4000,
                    position: 'top-right',
                    type: 'success',
                });
                this.closeModal();
            },
            closeModal() {
                this.resetRedirectPath();
                this.setModal({
                    show: false,
                    type: 'login',
                });
            },
            showRegisterPopup() {
                this.closeModal();
                setTimeout(() => {
                    this.setModal({
                        show: true,
                        type: 'registration',
                    });
                }, 200);
            },
            openResetPassword() {
                this.closeModal();
                setTimeout(() => {
                    this.setModal({
                        show: true,
                        type: 'requestReset',
                    });
                }, 200);
            },
            reSend() {
                this.errors = formValidations(this.input);
                if (Object.keys(this.errors).length !== 0) {
                    return;
                }
                this.sendingResent = true;
                this.reSendVerification(this.input.email);
            },
        },
        mounted() {
            if (this.toast) {
                this.$toast.open({
                    message: this.$t('notification.confirmedSuccess'),
                    type: 'success',
                    duration: 10000,
                });
            }
        },
        watch: {
            possibilityToReSend: {
                handler(reSent) {
                    if (!reSent) {
                        this.sendingResent = false;
                        this.closeModal();
                        this.$toast.open({
                            message: this.$t('notification.verificationEmailResent'),
                            duration: 10000,
                            position: 'bottom-right',
                            type: 'success',
                        });
                        setTimeout(() => {
                            this.setModal({
                                show: true,
                                type: 'reSent',
                            });
                        }, 200);
                    }
                },
            },
        },
    };
</script>
