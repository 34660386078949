<template>
    <div class="social-signup">
        <b-button
            variant="text"
            class="stroke icon lg"
            @click="submitGoogleRequest()"
        >
            <img
                src="/images/google.svg"
                alt="google icon"
            ><span>{{
                $t('modal.login.googleRegisterButton')
            }}</span>
        </b-button>
        <b-button
            variant="text"
            class="hide stroke icon lg"
            @click="submitFacebookRequest()"
        >
            <img
                src="/images/facebook.svg"
                alt="facebook icon"
            ><span>{{
                $t('modal.login.facebookRegisterButton')
            }}</span>
        </b-button>
    </div>
</template>
<script>
    import { mapActions, mapMutations, mapState } from 'vuex';
    export default {
        name: 'SocialLogin',
        computed: {
            ...mapState('auth', ['redirectPath']),
        },
        methods: {
            ...mapMutations('auth', ['resetRedirectPath']),
            ...mapActions('auth', ['googleUrlRequest', 'facebookUrlRequest']),
            async submitGoogleRequest() {
                const routeData = {
                    path: this.redirectPath == '' ? this.$route.path : this.redirectPath,
                    params: { ...this.$route.params },
                    query: { ...this.$route.query },
                };
                localStorage.setItem('loginRedirect', JSON.stringify(routeData));
                this.resetRedirectPath();
                await this.googleUrlRequest();
            },
            async submitFacebookRequest() {
                // TODO ADD THIS AFTER WE GO LIVE
                // await this.facebookUrlRequest();
            },
        },
    };
</script>
<style lang="scss" scoped>
.social-signup {
  .btn {
    width: 100%;
    img {
      margin-right: 8px;
    }
  }
}
</style>
